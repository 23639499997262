<script setup>
const $route = useRoute();
const userStore = useUserStore();

// ==================== CONSTANTS ====================

const navigation = shallowRef([
  { name: "Marketplace", icon: resolveComponent("IconBasket"), ref: "marketplace" },
  { name: "Terms and Conditions", icon: resolveComponent("IconInfo"), ref: "terms-and-conditions" },
  { name: "User Guide", icon: resolveComponent("IconUserInfo"), ref: "user-guide" },
  { name: "Hero Section", icon: resolveComponent("IconImage"), ref: "hero-section" },
  { name: "Featured", icon: resolveComponent("IconStar"), ref: "featured" },
]);

// ==================== MOUNT ====================

onMounted(() => {
  if (userStore.IS_BIG_BOSS) {
    navigation.value = [
      { name: "Marketplace", icon: resolveComponent("IconBasket"), ref: "marketplace" },
      { name: "Users Wallets", icon: resolveComponent("IconWallet"), ref: "users-wallets" },
      { name: "Collections", icon: resolveComponent("IconBookMark"), ref: "collections" },
      { name: "Utilities", icon: resolveComponent("IconFlight"), ref: "utilities" },
      { name: "Terms and Conditions", icon: resolveComponent("IconInfo"), ref: "terms-and-conditions" },
      { name: "User Guide", icon: resolveComponent("IconUserInfo"), ref: "user-guide" },
      { name: "Hero Section", icon: resolveComponent("IconImage"), ref: "hero-section" },
      { name: "Minters", icon: resolveComponent("IconMinters"), ref: "minters" },
      { name: "Featured", icon: resolveComponent("IconStar"), ref: "featured" },
      { name: "NFTs", icon: resolveComponent("IconAssets"), ref: "nfts" },
      { name: "Settings", icon: resolveComponent("IconSettings"), ref: "settings" },
    ];
  }
});
</script>
<template>
  <div class="moving-gradient flex h-auto w-16 flex-col px-3 lg:w-80">
    <nav class="mt-6 flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li v-for="item in navigation" :key="item.name">
          <NuxtLink
            :to="`/cms/${item.ref}`"
            :class="[
              item.ref === $route.name ? 'pointer-events-none bg-white text-black' : 'text-white',
              'group flex w-fit gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 duration-0 hover:bg-white hover:text-black lg:w-full',
            ]"
          >
            <component
              :is="item.icon"
              :class="[
                item.ref === $route.name ? 'fill-black [&>rect]:stroke-black' : 'fill-white [&>rect]:stroke-white',
                'size-6 shrink-0 group-hover:fill-black group-hover:[&>rect]:stroke-black',
              ]"
              aria-hidden="true"
            />
            <span class="hidden lg:block">{{ item.name }}</span>
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
</template>
