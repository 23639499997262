<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2.5C9.1712 2.5 8.37634 2.82924 7.79029 3.41529C7.20424 4.00134 6.875 4.7962 6.875 5.625V6.25H13.125V5.625C13.125 4.7962 12.7958 4.00134 12.2097 3.41529C11.6237 2.82924 10.8288 2.5 10 2.5ZM14.375 6.25V5.625C14.375 4.46468 13.9141 3.35188 13.0936 2.53141C12.2731 1.71094 11.1603 1.25 10 1.25C8.83968 1.25 7.72688 1.71094 6.90641 2.53141C6.08594 3.35188 5.625 4.46468 5.625 5.625V6.25H3.125C2.79348 6.25 2.47554 6.3817 2.24112 6.61612C2.0067 6.85054 1.875 7.16848 1.875 7.5V15.9375C1.875 17.4639 3.16107 18.75 4.6875 18.75H15.3125C16.8205 18.75 18.125 17.5309 18.125 15.9863V7.5C18.125 7.16848 17.9933 6.85054 17.7589 6.61612C17.5245 6.3817 17.2065 6.25 16.875 6.25H14.375ZM3.125 7.5L3.125 15.9375C3.125 16.7736 3.85143 17.5 4.6875 17.5H15.3125C16.167 17.5 16.875 16.8042 16.875 15.9863V7.5H3.125Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.25 8.125C6.59518 8.125 6.875 8.40482 6.875 8.75V9.375C6.875 10.2038 7.20424 10.9987 7.79029 11.5847C8.37634 12.1708 9.1712 12.5 10 12.5C10.8288 12.5 11.6237 12.1708 12.2097 11.5847C12.7958 10.9987 13.125 10.2038 13.125 9.375V8.75C13.125 8.40482 13.4048 8.125 13.75 8.125C14.0952 8.125 14.375 8.40482 14.375 8.75V9.375C14.375 10.5353 13.9141 11.6481 13.0936 12.4686C12.2731 13.2891 11.1603 13.75 10 13.75C8.83968 13.75 7.72688 13.2891 6.90641 12.4686C6.08594 11.6481 5.625 10.5353 5.625 9.375V8.75C5.625 8.40482 5.90482 8.125 6.25 8.125Z"
    />
  </svg>
</template>
