<script setup>
import { signOut } from "~/services/auth.service";

const { $api } = useNuxtApp();
const $config = useRuntimeConfig();
const notificationStore = useNotificationStore();
const userStore = useUserStore();
const marketplaceStore = useMarketplaceStore();

// ==================== CONSTANTS ====================

const BSCSCAN_ADDRESS_URL = `https://${
  $config.public.bscScanUrl.includes("testnet") ? "testnet." : ""
}bscscan.com/address`;

const openSideBar = ref(false);
const sideBarAction = ref("");
const isAuthorized = ref(false);
const isRefreshing = ref(false);

// ==================== MOUNT ====================

onMounted(() => {
  isAuthorized.value = userStore.IS_AUTHORIZED;
});

// ==================== FUNCTIONS ====================

function showSideBar(action = "") {
  openSideBar.value = true;
  sideBarAction.value = action;
}

function hideSideBar() {
  openSideBar.value = false;

  if (sideBarAction.value) {
    sideBarAction.value = "";
  }
}

async function refreshBalances() {
  isRefreshing.value = true;

  const { data, error } = await $api.post("update-balance");

  if (error.value || data.value.error) {
    isRefreshing.value = false;

    return;
  }

  if (data.value.balance) {
    userStore.UPDATE_USER({
      balanceBNB: data.value.balance.BNB,
      BSCTokens: data.value.balance.CEEK,
    });
  }

  isRefreshing.value = false;

  notificationStore.ADD({
    type: "success",
    title: "Wallet info updated",
  });
}
</script>

<template>
  <header class="relative overflow-hidden bg-white text-xl shadow-lg dark:bg-the-header">
    <div class="rectangle-4077" />
    <div class="rectangle-4078" />
    <div class="relative">
      <nav
        class="mx-auto flex w-full max-w-[1920px] items-center justify-between px-6 py-2 lg:px-11"
        aria-label="Global"
      >
        <NuxtLink to="/" class="-m-1.5 size-16 p-1.5">
          <span class="sr-only">CEEK</span>
          <img src="/img/NFT_CEEK_logo.webp" class="size-full object-cover" alt="CEEK Logo header" />
        </NuxtLink>
        <ClientOnly>
          <div class="ml-auto mr-6">
            <MoleculeThemeSwitcher class="hover:shadow-around hover:shadow-black/25 dark:hover:shadow-[#00bbba]" />
          </div>
        </ClientOnly>
        <MoleculeClientWrapper>
          <template #body>
            <AtomButton aria-label="Sidebar" type="button" @click="showSideBar('user')">
              <div
                v-if="!userStore.user.avatar"
                class="custom-gradient relative flex size-12 items-center justify-center rounded-full transition-shadow hover:shadow-around hover:shadow-black/25 dark:hover:shadow-[#00bbba]"
              >
                <IconUserGradient class="size-7" />
              </div>
              <AtomImageNuxtImage
                v-else
                resize="48x48"
                bucket-alias="avatars"
                class="inline-block aspect-square size-12 rounded-full object-cover transition-shadow hover:shadow-around hover:shadow-black/25 dark:hover:shadow-[#00bbba]"
                :src="userStore.user.avatar"
                alt="avatar"
              />
            </AtomButton>
          </template>
          <template #skeleton>
            <div class="size-12 animate-pulse rounded-full bg-gray-300 dark:bg-[#2D059E]"></div>
          </template>
        </MoleculeClientWrapper>
      </nav>
      <MoleculeSideBar :open="openSideBar" @close-side-bar="hideSideBar">
        <template v-if="sideBarAction === 'user'">
          <div>
            <div class="relative z-10 mx-auto w-fit rounded-full bg-primary-gradient p-1">
              <div
                v-if="!userStore.user?.avatar"
                class="flex size-28 items-center justify-center rounded-full bg-white dark:bg-[#0F2260]"
              >
                <IconUserGradient class="size-20" />
              </div>
              <AtomImageNuxtImage
                v-else
                :src="userStore.user?.avatar"
                resize="112x112"
                alt="avatar"
                bucket-alias="avatars"
                onload="this.onload=null;this.classList.remove('opacity-0');"
                class="mx-auto size-28 rounded-full object-cover opacity-0 transition"
              />
              <span v-if="userStore.user.verify" class="absolute -left-3 -top-5">
                <IconCrown2 class="size-12" />
              </span>
            </div>
            <p class="mx-auto mt-2 w-fit text-2xl font-bold text-black/60 dark:text-white">
              {{ userStore.user.userName }}
            </p>
            <p class="mx-auto w-fit text-base font-normal dark:text-white">@{{ userStore.user.userName }}</p>
            <div class="mt-4 flex justify-between">
              <div class="flex gap-2">
                <IconWallet class="mt-0.5 size-6" />
                <p
                  class="flex items-center gap-2 bg-primary-gradient bg-clip-text text-lg font-medium text-transparent"
                >
                  CEEK
                  <span class="text-sm font-medium text-[#FFB629]">Active</span>
                </p>
              </div>
              <div
                class="flex max-w-[200px] items-center gap-2 bg-primary-gradient bg-clip-text text-sm font-semibold text-transparent"
              >
                <NuxtLink
                  :to="`${BSCSCAN_ADDRESS_URL}/${userStore.user.BSCWalletAddress}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="truncate"
                  >{{ userStore.user.BSCWalletAddress.slice(0, 6) }}...{{
                    userStore.user.BSCWalletAddress.slice(-4)
                  }}</NuxtLink
                ><AtomButton
                  aria-label="Copy Text"
                  class="h-fit"
                  type="button"
                  @click="copyText(notificationStore, userStore.user.BSCWalletAddress)"
                >
                  <IconCopyGradient class="size-6" />
                </AtomButton>
                <AtomButton
                  aria-label="Update Wallet Info"
                  :class="[
                    'h-fit',
                    {
                      'pointer-events-none': isRefreshing,
                    },
                  ]"
                  type="button"
                  @click="refreshBalances()"
                >
                  <IconRefresh
                    :class="[
                      'size-6 shrink-0',
                      {
                        'animate-spin': isRefreshing,
                      },
                    ]"
                  />
                </AtomButton>
              </div>
            </div>
            <div
              v-for="item in userStore.user.externalWalletProviders"
              :key="item.address"
              class="mt-4 flex justify-between"
            >
              <div class="flex gap-2">
                <IconWallet class="mt-0.5 size-6" />
                <p class="bg-primary-gradient bg-clip-text text-lg font-medium text-transparent">{{ item.provider }}</p>
              </div>
              <div
                class="flex max-w-[200px] gap-2 bg-primary-gradient bg-clip-text text-sm font-semibold text-transparent"
              >
                <NuxtLink
                  :to="`${BSCSCAN_ADDRESS_URL}/${item.address}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="truncate"
                  >{{ item.address.slice(0, 6) }}...{{ item.address.slice(-4) }}</NuxtLink
                >
                <AtomButton
                  aria-label="Copy Text"
                  class="h-fit"
                  type="button"
                  @click="copyText(notificationStore, item.address)"
                >
                  <IconCopyGradient class="size-6" />
                </AtomButton>
              </div>
            </div>
          </div>
          <div class="my-3 rounded-xl px-4 py-3 shadow-around shadow-black/20 dark:bg-[#2D059E]">
            <p class="text-lg font-medium text-black/60 dark:text-white">Available</p>
            <div class="flex items-center gap-3 border-b-2 border-[#45BFE4]/20 py-3">
              <img
                class="mt-1 aspect-square size-5 self-start object-cover opacity-0 transition"
                src="/img/ceek-coin.webp"
                alt="CEEK coin"
                onload="this.onload=null;this.classList.remove('opacity-0');"
              />
              <div class="mr-auto flex flex-col">
                <span class="truncate bg-gradient-blue bg-clip-text text-lg font-bold text-transparent dark:text-white"
                  >{{ userStore.user.BSCTokens.toFixed(2) }} CEEK</span
                >
                <span class="truncate text-base font-bold text-[#9288E0]"
                  >$ {{ (userStore.user.BSCTokens * marketplaceStore.GET_EXCHANGE_RATE.tokenPrice).toFixed(2) }}</span
                >
              </div>
            </div>
            <div class="flex items-center gap-3 pt-3">
              <IconBNB class="mt-1 aspect-square size-5 self-start" />
              <div class="mr-auto flex flex-col">
                <span class="truncate text-lg font-bold text-[#45BFE4] dark:text-white"
                  >{{ userStore.user.balanceBNB.toFixed(9) }} BNB</span
                >
                <span class="truncate text-base font-bold text-[#9288E0]"
                  >$ {{ (userStore.user.balanceBNB * marketplaceStore.GET_EXCHANGE_RATE.bnbPrice).toFixed(2) }}</span
                >
              </div>
            </div>
          </div>

          <div class="my-3 rounded-xl p-3 shadow-around shadow-black/20 dark:bg-[#2D059E]">
            <div class="border-b-2 border-[#45BFE4]/20 pb-3">
              <div class="flex gap-3 rounded-xl bg-blue-primary px-4 py-2 dark:bg-white">
                <IconAdmin class="size-6 fill-white dark:fill-[url(#admin-gradient)]" />
                <span class="text-base font-bold uppercase text-white dark:text-black">
                  {{ userStore.IS_BIG_BOSS ? "SUPER ADMIN" : "ADMIN" }}
                </span>
              </div>
            </div>
            <div class="border-b-2 border-[#45BFE4]/20 py-3">
              <NuxtLink
                to="/transactions"
                class="group flex gap-3 rounded-xl bg-transparent px-4 py-2 hover:bg-blue-primary active:scale-90 dark:hover:bg-white"
                @click="openSideBar = false"
              >
                <IconTransaction
                  class="size-6 group-hover:fill-white dark:fill-white dark:group-hover:fill-[url(#transaction-gradient)]"
                />
                <span
                  class="text-base font-bold uppercase text-black group-hover:text-white dark:text-white dark:group-hover:text-black"
                >
                  MY TRANSACTIONS
                </span>
              </NuxtLink>
            </div>
            <div class="border-b-2 border-[#45BFE4]/20 py-3">
              <NuxtLink
                to="/profile"
                class="group flex gap-3 rounded-xl bg-transparent px-4 py-2 hover:bg-blue-primary active:scale-90 dark:hover:bg-white"
                @click="openSideBar = false"
              >
                <IconUserGradient
                  class="size-6 group-hover:fill-white dark:fill-white dark:group-hover:fill-[url(#user-gradient)]"
                />
                <span
                  class="text-base font-bold uppercase text-black group-hover:text-white dark:text-white dark:group-hover:text-black"
                >
                  MY ACCOUNT
                </span>
              </NuxtLink>
            </div>
            <div class="pt-3">
              <NuxtLink
                to="/my-items/"
                class="group flex gap-3 rounded-xl bg-transparent px-4 py-2 hover:bg-blue-primary active:scale-90 dark:hover:bg-white"
                @click="openSideBar = false"
              >
                <IconAssets
                  class="size-6 group-hover:fill-white dark:fill-white dark:group-hover:fill-[url(#assets-gradient)]"
                />
                <span
                  class="text-base font-bold uppercase text-black group-hover:text-white dark:text-white dark:group-hover:text-black"
                >
                  MY ITEMS
                </span>
              </NuxtLink>
            </div>
          </div>
          <MoleculeButtonGradient
            aria-label="Logout"
            type="button"
            class="flex w-full items-center justify-center gap-2 rounded-xl p-2.5 text-lg font-semibold md:text-xl"
            @click="signOut($api, userStore)"
          >
            LOG OUT
            <IconLogOut class="size-6" />
          </MoleculeButtonGradient>
        </template>
      </MoleculeSideBar>
    </div>
  </header>
</template>
