<template>
  <div class="cms">
    <SICTheCmsHeader />
    <div class="flex h-full min-h-screen flex-row">
      <SICTheCmsSidebar />
      <div class="w-full dark:bg-[#0F2260]">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
